@import '../../style/variable.scss';

.organigramma {


    .ricerca-data {
        position: relative;
        top: 25%;
        padding: 40px 40px 20px 40px;
        background-color: #f5f5f5;
        border-radius: 13px;
        margin: auto 50px;

        .form-group {
            margin-bottom: 2rem;

            label {
                font-size: 1rem;
                padding-bottom: 10px;
                padding-left: 0;
            }
        }
    }
    
    .dipartimento {
        cursor: pointer;

        .card {
            height: 230px;
            position: relative;
            border-radius: 5px;
            -webkit-box-shadow: $box-shadow;
            box-shadow: $box-shadow;
            border: none;
            color: $color-principale;

            &:hover {
                background-color: $color-secondario;
                transition: all .5s;
                color: white;

                .color-icon-organigramma {
                    fill: white;
                    stroke: white;
                }
                .color-icon-organigramma-pa {
                    fill: white;
                    stroke: white;
                }
            }
            
            &::after {
                margin: 0;
            }

            .color-icon-organigramma {
                fill: $color-principale;
                width: 60px;
                height: 60px;
            }
            .color-icon-organigramma-pa {
                stroke: $color-principale;
                width: 40px;
                height: 40px;
            }

            .card-block {
                padding: 24px;
                
                .card-titolo{
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;

                    img {
                        margin-left: 15px;
                        &:not(.icon-generic) {
                            width: 60px;
                        }
                    }
                    span {
                        font-weight: bold;
                        margin-left: 20px;
                        font-size: 17px;
                    }
                }

                .card-descrizione{
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 1em;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        
    }
}


.organigramma-dettaglio-so {

    @media screen and (min-width: 768px) {
        #contatti{
        position: absolute;
        top: 0px;
        right: 0px;
        }    
    }

    .sintesi {
        .so-descrizione{
            max-width: 53rem;
            font-weight: 500;
        }
    }

    .info-box-contatti {
        background-color: #f5f2f2;
        border-radius: 4px;
        padding: 10px 20px;
        dd {
            word-wrap: break-word;
        }
    }

    .elenco-dip{
        margin-top: 10px;
    }

    .info-so {
        margin-top: 20px;
        padding: 26px 10px;
        border-top: 2px solid $border-color;
        border-bottom: 2px solid $border-color;

        &-item {
            display: flex;
            flex-direction: column;
            margin: 10px 0 10px 0;

            >:nth-child(1) {
                font-weight: bold;
                text-transform: uppercase;
            }
            >:nth-child(2) {
                font-weight: 500;
            }
        }
    }
    
}