body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-width-modal-header .modal-title {
  width: 100%;
}

.info-box-light {
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #cfcfcf;
}

textarea {
  resize: none;
}
