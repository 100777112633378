@import './variable.scss';
@import './mixin.scss';
@import './table.scss';
@import './form.scss';
@import './modal.scss';
@import './card.scss';
@import './callout.scss';
@import './steppers.scss';

body {
    color: $body-text-color;
    font-family: "Titillium web", Verdana, Arial, monospace;
    user-select: none;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

html { 
    scroll-behavior: smooth; 
}

.pointer {
    cursor: pointer;
}

.content-page {
    width: calc(100% - $width-sider-menu);
}

/* small.form-text.text-muted {
    color: var(--red) !important;
} */

.collapse-body .collapse-header button[aria-expanded=true]:before {
    width: 1.5rem;
    height: 1.5rem;
}

.required-checkbox-before::after {
    @include label-required();
}

.required {
    label::after {
        @include label-required();
    }
}

.required-label::after {
    @include label-required();
}

.notSaveElement {
    font-size: 23px;
}

.numberInputArrow {
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.dropdown-menu {
    &.no-arrow {
        &::before {
            content: none;
        }
    }
}

.dropdown-menu {
    font-size: 17px;
}


.link-list-wrapper ul li {
    a {
        white-space: nowrap;
        cursor: pointer;
        color: $color-principale;

        span {
            color: $color-principale;
            margin-right: 0;
        
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

:focus-visible {
    outline: none;
}

.opacity:focus-within > div {
    z-index:9999!important;
}

td ul {
    list-style-type: none;
}

.wrap-text {
    overflow-wrap: anywhere;
}

input::-ms-reveal {
    display:none;
}
.border {
   @include border();
}
.border-solid {
    border: 1px solid $border-color !important;
}

.info-box-light {
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #cfcfcf;

    dd {
        word-wrap: break-word;
    }
}

.info-box-light,
.info-box-light-so,
.info-box-light-po{
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #cfcfcf;

    .row {
        dl {
            .col-sm-4 {
                margin: 0px;
            }
        }
    }
    dd {
        word-wrap: break-word;
    }
    dl {
        margin-bottom: 0px;
        padding-left: 0;
    }
}

.info-box-light-candidato {
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #cfcfcf;

    dd {
        word-wrap: break-word;
    }
}

.bg-light-gray{
    background-color: $bg-color-box;
}

a:focus {
    box-shadow: none !important;
    outline: 0 !important;
}

hr {
    border-top: 2px solid $border-color;
}

.documentView {
    padding-bottom: 2em;

    h4 {
        text-align: center;
        padding-top: 5px;
    }

    strong {
        font-size: 14px;
    }
}

.icon {
    cursor: pointer;
}
.icon-align {
    vertical-align: sub;
}
.icon.icon-primary{
    fill: $color-principale !important;
}
.icon.icon-warning {
    fill: $color-warning !important;
}

.icon.disabled {
    cursor: not-allowed;
    fill: $color-disabled !important;
}

.container {
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 80%;
}

.badge-primary {
    background-color: $color-principale;
}

.float-end {
    float: right;
}
.font-bold {
    font-weight: bold;
}
.font-weight-500 {
    font-weight: 500 !important;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.hidden{
    display: none;
}

.scroll-up {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: $color-principale;
    padding: 10px;
    border-radius: 7px;
    color: white;
    font-weight: 600;
}

.btn {
    text-wrap: nowrap;
    height: fit-content;

    .disabled, 
    &:disabled {
        color: #06c;
        background-color: transparent;
        cursor:not-allowed;
    }
    /* &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */
}

.document-upload .btn {
    height: 2rem;
    padding: 5px 10px;
}

.opacity.select-cursor-no-margin {
    > div > div > div:nth-of-type(2) {
        padding: 0px;
        margin: 0px;
    }
}

input[id^="react-select"] {
    &:focus:not(.focus-mouse):not([data-focus-mouse=true]) {
        border-color: transparent !important;
        box-shadow: none !important;
        outline: none !important;
    }
}

